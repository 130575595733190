import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import ShelfDetail from './ShelfDetail';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';

const MerchandiseDetail = ({
  merchandiser,
}: {
  merchandiser: Merchandiser;
}) => {
  const { refrigeratedShelfs, heatedShelfs } = merchandiser;

  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));

  const hasHeatedShelfs = heatedShelfs?.length > 0;

  const { data: alarmsActive } = useAppSelector((state) =>
    getAlarmActiveBySelector(state, {
      groupId: merchandiser.id,
    })
  );

  return (
    <Grid item md={12} sm={12} xs={12}>
      <Grid
        gap={'1rem'}
        display={'grid'}
        gridTemplateColumns={
          isLaptop || !hasHeatedShelfs ? '1fr' : 'repeat(2, minmax(0, 1fr));'
        }
      >
        <Box flex={'1'} sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledHeader error={!!alarmsActive.length} isOn={true}>
            Refrigerated
          </StyledHeader>
          {refrigeratedShelfs?.map((refrigerated, i) => (
            <ShelfDetail
              merchandiser={merchandiser}
              commanderId={refrigerated.commanderId}
              controlUnitId={refrigerated.controlUnitId}
              variant="refrigerated"
              displayZone={hasHeatedShelfs}
              key={refrigerated.commanderId}
            />
          ))}
        </Box>
        {hasHeatedShelfs && (
          <Box flex={'1'} sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledHeader error={!!alarmsActive.length} isOn={true}>
              Heated
            </StyledHeader>
            {heatedShelfs?.map((heated, i) => {
              return (
                <ShelfDetail
                  merchandiser={merchandiser}
                  commanderId={heated.commanderId}
                  controlUnitId={heated.controlUnitId}
                  variant="heated"
                  key={heated.commanderId}
                  itemNumber={i + 1}
                />
              );
            })}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const StyledHeader = styled(Box)<{ error: boolean; isOn: boolean }>(
  ({ error, isOn, theme }) => `
    background: ${
      error ? theme.palette.error.main : theme.palette.primary.dark
    };  
    color: ${theme.palette.common.white};
    width: 100%;
    text-align: center;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 0.8rem 0.8rem 0 0;
    ${!isOn ? `background: ${theme.palette.gray.dark}` : ''}
`
);
export default MerchandiseDetail;
