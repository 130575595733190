import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material';
import { AppearanceIcon, GridIcon, ListIcon } from '../../../theme/icons';
import SummaryPanel from '../Panels/SummaryPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useParseErrorMessage } from '../../../../../handlingErrors';
import MerchandiseOverview from './MerchandiseOverview/MerchandiseOverview';
import AlarmsOverview from './AlarmsOverview/AlarmsOverview';

function DashboardPageFW() {
  const [gridDisplay, setGridDisplay] = useState(true);
  const changeAppearance = (displayGrid: boolean) => {
    setGridDisplay(displayGrid);
  };
  const theme = useTheme();
  const { merchandisers, error, isLoading } = useAllMerchandisers();
  const parseErrorMessage = useParseErrorMessage();

  return (
    <>
      <Grid container spacing={1}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{parseErrorMessage(error)}</Alert>
          </Grid>
        )}
        {/* TODO: change for skeleton of components, or the components support loading, but i'm not sure at the moment */}
        {isLoading && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={12} sm={9} md={10}>
          <SummaryPanel />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap="1rem"
            padding={'0 1rem'}
          >
            <Button
              variant="text"
              disableFocusRipple={true}
              sx={{
                color: theme.palette.common.black,
                cursor: 'default',
                '&:hover': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
              startIcon={<AppearanceIcon fontSize="1.5rem" />}
            >
              Appareance
            </Button>
          </Box>
          <Box display={'flex'}>
            <IconButton
              size="large"
              sx={{
                backgroundColor: `${
                  gridDisplay
                    ? theme.palette.secondary.main
                    : theme.palette.primary.contrastText
                }`,
                borderRadius: '1rem',
                padding: '1rem',
                margin: '0.5rem',
                width: '4rem',
              }}
              onClick={() => changeAppearance(true)}
            >
              <GridIcon color={theme.palette.primary.main} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: `${
                  !gridDisplay
                    ? theme.palette.secondary.main
                    : theme.palette.primary.contrastText
                }`,
                borderRadius: '1rem',
                padding: '1rem',
                margin: '0.5rem',
                width: '4rem',
              }}
              size="large"
              onClick={() => changeAppearance(false)}
            >
              <ListIcon color={theme.palette.primary.main} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <MerchandiseOverview
          gridDisplay={gridDisplay}
          merchandiseItems={merchandisers}
        />
      </Grid>
      <Grid container>
        <AlarmsOverview gridDisplay={gridDisplay} />
      </Grid>
    </>
  );
}

export default DashboardPageFW;
