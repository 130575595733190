import { Typography, alpha, styled } from '@mui/material';
import React from 'react';

export interface MerchandiseShelfProps {
  variant: 'refrigerated' | 'heated';
  error?: boolean;
  description?: React.ReactNode;
  value: React.ReactNode;
  unit: React.ReactNode;
  isOn?: boolean;
}
export function MerchandiseShelf(props: MerchandiseShelfProps) {
  const { error, description, value, unit, variant, isOn } = props;
  return (
    <Shelf variant={variant} error={error} isOn={isOn}>
      {description && <ShelfDescription>{description}</ShelfDescription>}
      <ShelfValue variant={variant}>
        {value}
        {isOn ? <TempInfo>{unit}</TempInfo> : null}
      </ShelfValue>
    </Shelf>
  );
}

const Shelf = styled('div')<{
  error?: boolean;
  variant?: 'refrigerated' | 'heated';
  isOn?: boolean;
}>(
  ({ error, theme, variant, isOn }) => `
    line-height: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.text.primary};
    height: 100%;
    overflow: hidden;
  
    ${
      error
        ? `
      background: ${alpha(theme.palette.error.light, 0.4)};
      color: ${theme.palette.error.main};   
      `
        : ''
    }

    ${
      variant === 'refrigerated'
        ? `
      align-items: 'center';
      `
        : ''
    }
     ${
       !isOn
         ? `
         background-color: ${alpha(theme.palette.gray.light, 0.7)};
         color: ${theme.palette.gray.dark};
          `
         : ''
     }
  `
);
const ShelfDescription = styled('div')`
  font-size: 0.75rem;
  align-self: flex-start;
  font-weight: 600;
  padding: 0 1rem;
`;

const ShelfValue = styled(Typography as any, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  error?: boolean;
  variant?: 'refrigerated' | 'heated';
}>(
  ({ variant }) =>
    `
  font-size: ${variant == 'refrigerated' ? '2.5rem' : '1.5rem'};
  display: flex;
  color: inherit;
`
);
const TempInfo = styled('sup')`
  font-size: 1.1rem;
`;

export default MerchandiseShelf;
