import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha,
  styled,
} from '@mui/material';
import { Merchandiser } from '../../../redux/composableDevice/model/Merchandiser.model';
import MerchandiseShelfConnected from '../../../components/Dashboard/DashboardPage/MerchandiseOverview/MerchandiseShelfConnected';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import { useNavigate } from 'react-router-dom';

const MerchandiseTable = (props: { data: Merchandiser[] }) => {
  const { data } = props;
  const navigate = useNavigate();
  const onClick = ({ id }: Merchandiser) => {
    navigate(`/merchandiser/${id}`);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: 'transparent',
        border: 'none',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: 'separate',
          borderSpacing: '0 0.8rem',
          padding: '0.5rem',
        }}
        aria-label="Merchandise items"
      >
        <TableHead sx={{ bgcolor: 'neutral.darker' }}>
          <StyledHeaderRow>
            <TableCell sx={{ width: '20%' }}>Product Name</TableCell>
            <TableCell sx={{ width: '10%' }}>Product Type</TableCell>
            <TableCell sx={{ width: '20%' }}>Temp Type</TableCell>
            <TableCell sx={{ width: '40%' }}>Current Temperature</TableCell>
            <TableCell sx={{ width: '10%' }}>Alarms</TableCell>
          </StyledHeaderRow>
        </TableHead>
        <TableBody>
          {data.map((merchandiser: Merchandiser) => {
            const hasRefrigerated = !!merchandiser.refrigeratedShelfs.length;
            const hasHeated = !!merchandiser.heatedShelfs.length;
            const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
              useAppSelector((state) =>
                getAlarmActiveBySelector(state, {
                  groupId: merchandiser.id,
                })
              );

            // TODO: Implement the logic for alarmsHeated and alarmsRefrigerated
            const alarmsHeated = alarmsActive.length > 0;
            const alarmsRefrigerated = alarmsActive.length > 0;

            return (
              <StyledTableRow
                key={merchandiser.name}
                sx={{ boxShadow: 2 }}
                error={alarmsHeated || alarmsRefrigerated}
                // TODO: Change to component Link when updating to mui/material v16
                onClick={() => onClick(merchandiser)}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    fontWeight: '300',
                  }}
                >
                  {merchandiser.name}
                </TableCell>
                <TableCell>
                  {merchandiser.composableBluePrint?.taqs?.family}
                </TableCell>
                <TableCell
                  sx={{
                    color: alarmsActive.length > 0 ? 'error' : 'common.black',
                  }}
                >
                  {hasRefrigerated ? 'Refrigerated' : null}
                  {hasRefrigerated && hasHeated ? `${' / '}` : ''}
                  {hasHeated ? 'Heated' : null}
                </TableCell>
                <TableCell
                  sx={{
                    color: alarmsActive.length > 0 ? 'error' : 'common.black',
                  }}
                >
                  {hasRefrigerated
                    ? merchandiser.refrigeratedShelfs.map((shelf) => (
                        <MerchandiseShelfConnected
                          commanderId={shelf.commanderId}
                          controlUnitId={shelf.controlUnitId}
                          variant="refrigerated"
                          key={shelf.commanderId}
                          type="text"
                        />
                      ))
                    : null}
                  {hasRefrigerated && hasHeated ? ' / ' : null}
                  {merchandiser.heatedShelfs.length
                    ? merchandiser.heatedShelfs
                        .map<React.ReactNode>((shelf) => (
                          <MerchandiseShelfConnected
                            commanderId={shelf.commanderId}
                            controlUnitId={shelf.controlUnitId}
                            variant="heated"
                            key={shelf.commanderId}
                            type="text"
                          />
                        ))
                        .reduce((prev, curr) => [prev, ', ', curr])
                    : null}
                </TableCell>
                {/* TODO: Change for heatedAlarms + refrigeratedAlarms */}
                <TableCell>{alarmsActive.length}</TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledHeaderRow = styled(TableRow)`
  & .MuiTableCell-root {
    text-align: center;
    font-weight: 600;
  }
`;
const StyledTableRow = styled(TableRow)<{ error: boolean }>(
  ({ error, theme }) => `
  border-radius: 1rem;
  background: ${theme.palette.primary.contrastText};
  padding: 0.5rem;

  & .MuiTableCell-root {
    border: none;
    text-align: center;

    &:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    ${
      error &&
      `
      background: ${alpha(theme.palette.error.light, 0.4)}; 
      color: ${theme.palette.error.main};
      &:first-child {
        background: ${theme.palette.error.main}; 
        color: ${theme.palette.primary.contrastText};
      }
      `
    }
  }
`
);

export default MerchandiseTable;
