import { createSelector } from '@reduxjs/toolkit';
import { getGroups } from '../../../../../redux/devices/selectors';
import GroupModel from '../../../../../redux/groups/api/GroupModel';
import { getGroupIdProps } from '../../../../../redux/groups/selectors';
import { Group } from '../../../../../redux/groups/api/group.model';
import { ComposableDevice } from '../../../../../redux/composableDevice/ComposableDevice.model';
import { getComposableDeviceFromGroupAttributes } from '../../../../../redux/composableDevice/util/getComposableDeviceFromGroupAttributes';
import { Merchandiser } from '../model/Merchandiser.model';
import bluePrints from '../data/blue-print.json';
import _ from 'lodash';

/**
 * List of supported composable device types
 *
 * Those ids comes from the real ids that Structural Concepts uses for their devices
 * So are areal family/group of devices that we support, at the momento Oasis merchandisers
 */
const listTypeSupported = ['B5024RH', 'B35R'];

const getBluePrintFromGroup = (group: Group | undefined) => {
  const data = getComposableDeviceFromGroupAttributes(
    group
  ) as ComposableDevice['composableDeviceData'];
  return data && bluePrints.find((bluePrint) => bluePrint.type === data.type);
};

const getRefrigeratedShelfs = (composableDevice: Group) => {
  const composableDeviceData =
    getComposableDeviceFromGroupAttributes(composableDevice);
  return (
    composableDeviceData?.taqs?.cus_refrigerated
      ?.split(',')
      .map((shelf: string) => {
        const [commanderId, controlUnitId] = shelf.split('/');
        return {
          commanderId,
          controlUnitId,
        };
      }) ?? []
  );
};

const getHeatedShelfs = (composableDevice: Group) => {
  const composableDeviceData =
    getComposableDeviceFromGroupAttributes(composableDevice);

  return (
    composableDeviceData?.taqs?.cus_heated?.split(',').map((shelf: string) => {
      const [commanderId, controlUnitId] = shelf.split('/');
      return {
        commanderId,
        controlUnitId,
      };
    }) ?? []
  );
};

export const getAllComposableDeviceByPropGroupIdSelector = createSelector(
  [getGroupIdProps, getGroups],
  (_groupId, groups) => {
    return GroupModel.getGroupsByPredicate(
      (group: Group | undefined) => {
        const data = getComposableDeviceFromGroupAttributes(
          group
        ) as ComposableDevice['composableDeviceData'];

        return data && listTypeSupported.includes(data.type);
      },
      // TODO: support regional case to filter inside a group
      groups
    ).map((group) => {
      const composableDeviceData =
        getComposableDeviceFromGroupAttributes(group);
      const composableBluePrint = getBluePrintFromGroup(group);
      const refrigeratedShelfs = getRefrigeratedShelfs(group);
      const heatedShelfs = getHeatedShelfs(group);
      return {
        ...group,
        // Merchandiser only with one device
        deviceId: _.first(group.devices),
        composableDeviceData,
        composableBluePrint,
        refrigeratedShelfs,
        heatedShelfs,
      } as Merchandiser;
    });
  }
);

export const getMerchandiserByMerchandiserIdPropSelector = createSelector(
  [
    (_state, props: { merchandiserId?: string }) => props.merchandiserId,
    getAllComposableDeviceByPropGroupIdSelector,
  ],
  (merchandiserId, merchandisers) => {
    return merchandisers.find(
      (merchandiser) => merchandiser.id === merchandiserId
    );
  }
);
