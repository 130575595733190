import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { AlarmIcon } from '../../../../theme/icons';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getAlarmActiveBySelector } from '../../../../../../redux/incident/selectors/getAlarmActiveBySelector';
import AlarmCard, {
  SEVERITY,
} from '../../../../theme/components/AlarmCard/AlarmCard';
import AlarmTable from '../../../../theme/components/AlarmTable/AlarmTable';
interface MerchandiseOverviewProps {
  gridDisplay: boolean;
}
function AlarmsOverview(props: MerchandiseOverviewProps) {
  const { gridDisplay } = props;
  const theme = useTheme();

  const { data: alarmsActive, isLoading: isLoadingAlarmsActive } =
    useAppSelector((state) => getAlarmActiveBySelector(state, {}));
  const alarms = useMemo(
    () =>
      alarmsActive.map((alarm) => ({
        title:
          alarm.eventTranslation?.shortText ??
          alarm.event ??
          alarm.summary ??
          '',
        description: alarm.eventTranslation?.longText ?? '',
        timestamp: alarm.timestamp,
        task: `${alarm.event}.task_to_solve`,
        severity: SEVERITY.HIGH,
        uuid: alarm.uuid,
      })),
    [alarmsActive]
  );
  let content = gridDisplay ? (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {alarms.map((alarm) => (
        <AlarmCard key={alarm.title} {...alarm} />
      ))}
    </Box>
  ) : (
    <AlarmTable data={alarms} />
  );
  if (isLoadingAlarmsActive) {
    content = <Skeleton variant="rectangular" height={200} width={'100%'} />;
  }
  return (
    <>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} gap="1rem" padding={'1rem'}>
          <AlarmIcon fontSize="1.5rem" color={theme.palette.common.black} />
          <Typography color="common.black" fontWeight="600">
            Alarms
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </>
  );
}

export default AlarmsOverview;
